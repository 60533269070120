{
    "configs": [
        {
            "courierName":"dhl",
            "weightOptions": []
        },
        {
            "courierName": "postnord",
            "weightOptions": [3,5,10]
        }
    ]
}