export const environment = {
    production: false,
    msalConfig: {
        auth: {
            clientId: 'd45c2617-5488-4152-95ee-3309701ee87e',
            authority: 'https://c9b8ece4-f7f7-45b0-acb3-3f69aabbd74c.ciamlogin.com/c9b8ece4-f7f7-45b0-acb3-3f69aabbd74c/'
        }
    },
    apiConfig: {
        scopes: ['user.read'],
        uri: 'https://graph.microsoft.com/v1.0/me'
    },
    backendConfig: {
      scopes: ['api://f4b42b1d-7f99-4f17-945d-2d38cbafe7e9/.default'],
      uri: ' http://localhost:7139/*',
      apiUrl: ' http://localhost:7139/api'
  }
};
