<div *ngIf="isLoading" class="loading-spinner">
    <mat-spinner></mat-spinner>
</div>

  <div *ngIf="!isLoading">
    <h2>Ange maxvikt</h2>
    <div class="rectangle">
      <h1>{{ courierName }}</h1>
      <div *ngFor="let option of courierWeighOptions">
        <button mat-raised-button color="orange" [routerLink]="['/qr', courier]" [queryParams]="{ weight: option.toString() }">{{option}} KG</button>
      </div>
      <button *ngIf="courierWeighOptions.length === 0" mat-raised-button color="orange" [routerLink]="['/qr', courier]">FORTSÄTTA</button>
    </div>
    <br />
    <button mat-raised-button color="orange" [routerLink]="['/couriers']">STÄNG</button>
  </div>