<div>
  <img src="assets/smd-logo.jpg" alt="Logo" class="logo">

  @if (loginDisplay) {
    <div>
      <button mat-raised-button color="orange" class="logout-button" (click)="logout()" >LOGGA UT</button>
    </div>
  }

  @if (!loginDisplay) {
    <div class="welcome">
      <h2>Välkommen till<br />SMD Logistics returbokning</h2>
      <button mat-raised-button color="orange" (click)="loginRedirect()">LOGGA IN</button>
      <div *ngIf="!prodEnv"><h2>Dev (DevOps)</h2></div>
    </div>
  }

</div>
<router-outlet></router-outlet>
