import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import { RouterLink, ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import couriersConfigs from '../../assets/courier-pakcage-configs.json';

interface Shipment {
  ShipmentId: string;
  Weight: number;
  // Add other fields as necessary
}


@Component({
  selector: 'app-weight',
  standalone: true,
  imports: [
    RouterLink,
    MatButtonModule,
    CommonModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './weight.component.html',
  styleUrl: './weight.component.css',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class WeightComponent implements OnInit {
  courier: string | null = null;
  weight: string | null = null;
  courierName: string | null = null;
  shipment: Shipment | undefined;
  shipmentId: string | undefined;
  isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    // setTimeout(() => {
    this.route.paramMap.subscribe(params => {
      this.courier = params.get('courier');
      if (this.courier == 'postnord') {
        this.courierName = 'Postnord';
        this.loadPostnordShipments();
      } else if (this.courier == 'dhl') {
        this.courierName = 'DHL';
      } else {
        this.router.navigate(['/couriers']);
      }
      console.log(this.courier);
      this.isLoading = false;
    });
    // }, 5000); // Delay execution by 5 seconds
    }

    private loadPostnordShipments(): void {
      const shipmentObserver = {
        next: (shipment: Shipment) => {
          this.shipment = shipment;

          console.log('Ive got a shipmentId')
          console.log(this.shipment);
          console.log(this.shipment.ShipmentId);

          this.router.navigate([`/qr/${this.courier}`], {
            queryParams: { weight: this.shipment.Weight, shipmentid: this.shipment.ShipmentId }
          });

          this.isLoading = false;
        },
        error: (err: any) => {
          console.log('No assigned shipments available')
          this.isLoading = false;
        }
      };

      this.http.get<Shipment>(environment.backendConfig.apiUrl + '/shipments/postnord')
        .subscribe(shipmentObserver);
    }

    get courierWeighOptions(): number[] {
      const courierOptions = couriersConfigs.configs.find(x => x.courierName === this.courier);
      if(courierOptions)
        return courierOptions.weightOptions;
      else
        return [];
    }

 }
