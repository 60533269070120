import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: [],
    standalone: true
})
export class ProfileComponent implements OnInit {
  profile: ProfileType | undefined;
  api: object | undefined;
  envType: string = "";

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.getProfile(environment.apiConfig.uri + '?$select=displayName,companyName');
    this.getAPI(environment.backendConfig.apiUrl + '/shipments/postnord');
  }

  getProfile(url: string) {
    this.http.get(url)
      .subscribe(profile => {
        this.profile = profile;
      });
  }

  getAPI(url: string) {
    this.http.get(url)
      .subscribe(api => {
        this.api = api;
      });
  }
}
