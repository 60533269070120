import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-couriers',
  standalone: true,
  imports: [
    RouterLink,
    MatButtonModule,
    CommonModule
  ],
  template: `
  <h2>Välj ombud för att boka retur</h2>
  <div class="rectangle">
    <button mat-raised-button color="orange" [routerLink]="['/weight/postnord']">Postnord</button>
    <button mat-raised-button color="orange" [routerLink]="['/weight/dhl']">DHL</button>
  </div>
  <button mat-raised-button color="orange" [routerLink]="['/tracking']">Tracking</button>
  <button mat-raised-button color="orange" [routerLink]="['/fulltracking']">Historik</button>
  <div *ngIf="!prodEnv"><h2>Dev</h2></div>
`,
  styleUrls: ['./couriers.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouriersComponent implements OnInit {
  user: AccountInfo | null = null;
  userTenantId: string | undefined= '';
  prodEnv: boolean = false;

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService)
  { }

  ngOnInit() {
    this.prodEnv = environment.production;
    this.user = this.authService.instance.getActiveAccount();
    console.log('After getActiveAccount');
    if (this.user?.idTokenClaims) {
      this.userTenantId = this.user.idTokenClaims.tid;
      console.log('userTenantId: ', this.userTenantId);
    }
  }
}
